import React, { useContext, useEffect, useRef, useState } from "react";
import Slider from "rc-slider";
import axios from "axios";
import App from "../../components/App";
import GlobalContext from "../../context/GlobalContext";
// import DiscordIcon from "../../assets/image/discord-icon.svg"
import { FaCog, FaPlus } from "react-icons/fa";
import DiscordIcon from "../../components/Icons/DiscordIcon";
import {
  API_URL_V1,
  DISCORD_CLIENT_ID,
  DISCORD_REDIRECT_URL,
  SLACK_REDIRECT_URL,
} from "../../config";
import "rc-slider/assets/index.css";
import SlackIcon from "../../components/Icons/SlackIcon";

// const CATEGORIES = {
//   HATE: "hate",
//   THREATENING: "hate/threatening",
//   VIOLENCE: "violence",
//   SELF_HARM: "self-harm",
//   SEXUAL: "sexual",
// };

const SCALE = {
  0.1: { label: "Very Light" },
  0.3: { label: "Light" },
  0.5: { label: "Moderate" },
  0.7: { label: "Aggressive" },
  0.9: { label: "Very Aggressive" },
};

// const DEFAULT_CONFIG = {
//   [CATEGORIES.HATE]: 0.5,
//   [CATEGORIES.THREATENING]: 0.5,
//   [CATEGORIES.VIOLENCE]: 0.5,
//   [CATEGORIES.SELF_HARM]: 0.5,
//   [CATEGORIES.SEXUAL]: 0.5,
// };

const IntegrationIcon = (name, props) => {
  switch (name) {
    case "discord":
      return <DiscordIcon width="1.2rem" height="1.2rem" {...props} />;

    case "slack":
      return <SlackIcon width="1.2rem" height="1.2rem" {...props} />;
    default:
      return null;
  }
};

const Integrate = () => {
  const { session } = useContext(GlobalContext);
  const [refreshAttempt, setRefreshAttempt] = useState(0);
  const [integrations, setIntegrations] = useState(null);
  const [currentIntegration, setCurrentIntegration] = useState(null);
  useEffect(() => {
    if (!session) return;

    axios
      .get(`${API_URL_V1}/integrations`, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      })
      .then((res) => setIntegrations(res.data))
      .catch((err) => console.log(err));
  }, [session, refreshAttempt]);

  if (!session) return null;

  const discordOauth2Url = `https://discord.com/api/oauth2/authorize?client_id=${DISCORD_CLIENT_ID}&permissions=1443109022726&redirect_uri=${encodeURIComponent(
    DISCORD_REDIRECT_URL
  )}&response_type=code&scope=bot&state=${session.token}`;

  const slackOauth2Url = `https://slack.com/oauth/v2/authorize?client_id=4706529932278.4736929769344&scope=chat:write,im:write,incoming-webhook,channels:history,groups:history&user_scope=&redirect_uri=${encodeURIComponent(
    SLACK_REDIRECT_URL
  )}&state=${session.token}`;

  const showSettingsModal = (integration) => {
    setCurrentIntegration(integration);
  };

  return (
    <App>
      <h4>Integrate</h4>

      <div className="mt-8">
        {integrations && integrations.length > 0 ? (
          <div>Your integrations</div>
        ) : (
          <div>You don't have any integrations. Add one from below:</div>
        )}
        <div className="mt-2">
          {integrations?.map((ig, index) => (
            <div key={ig.foreignId} className="d-flex align-items-center mb-2">
              <span className="mr-8">{index + 1}</span>
              {IntegrationIcon(ig.name)}
              {ig.description ? (
                <span className="ml-8">{ig.description}</span>
              ) : (
                <span className="text-capitalize ml-8">{ig.name}</span>
              )}
              <span className="ml-8 small">
                Added on {new Date(ig.createdAt).toLocaleDateString()}
              </span>
              <button
                className="btn btn-outline-primary rounded-5 btn-xs ml-8"
                data-toggle="modal"
                data-target="#integrationSettingsModal"
                onClick={() => showSettingsModal(ig)}
              >
                <FaCog />
              </button>
            </div>
          ))}
        </div>
      </div>

      <a
        href={discordOauth2Url}
        className="btn btn-outline-primary rounded-5 d-inline-flex align-items-center mt-8 w-25"
      >
        {/* <img src={discordIcon} height={22} /> */}
        <DiscordIcon />
        <div className="font-size-6 ml-4">Discord</div>
        <FaPlus className="ml-4" />
      </a>

      <a
        href={slackOauth2Url}
        className="btn btn-outline-primary rounded-5 d-inline-flex align-items-center mt-8 ml-6 w-25"
      >
        {/* <img src={discordIcon} height={22} /> */}
        <SlackIcon />
        <div className="font-size-6 ml-4">Slack</div>
        <FaPlus className="ml-4" />
      </a>

      <IntegrationSettingsModal
        integration={currentIntegration}
        onSaveSuccess={() => setRefreshAttempt((p) => p + 1)}
      />
    </App>
  );
};

const IntegrationSettingsModal = ({ integration, onSaveSuccess }) => {
  const formRef = useRef(null);
  const { session } = useContext(GlobalContext);
  const [saving, setSaving] = useState(false);
  const [name, setName] = useState("");
  const [actionType, setActionType] = useState("warn");
  const [warningText, setWarningText] = useState("");
  const [behavior, setBehavior] = useState(0.1);

  useEffect(() => {
    if (!integration) return null;

    setName(integration.description || "");
    setActionType(integration.meta.action_type || "warn");
    setWarningText(integration.meta.warning_text || "");
    setBehavior(integration.meta.behavior || 0.1);
  }, [integration]);

  if (!integration || !session) return null;

  const onSave = (e) => {
    e.preventDefault();
    setSaving(true);
    const formData = new FormData(e.target);
    const data = {
      behavior,
      foreignId: integration.foreignId,
      ...Object.fromEntries(formData.entries()),
    };
    axios
      .put(`${API_URL_V1}/integrations`, data, {
        headers: {
          Authorization: `Bearer ${session.token}`,
        },
      })
      .then(() => {
        document.getElementById("close_modal_btn").click();
        onSaveSuccess?.();
      })
      .catch((er) => console.log(er))
      .finally(() => setSaving(false));
  };
  return (
    <div
      className="modal fade"
      id="integrationSettingsModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="integrationSettingsModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title d-flex align-items-center text-capitalize"
              id="integrationSettingsModalLongTitle"
            >
              {IntegrationIcon(integration.name, {
                width: "1.5rem",
                height: "1.5rem",
              })}{" "}
              <span className="ml-5">bot settings</span>
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={onSave} ref={formRef}>
              <div className="form-group">
                <label className="mb-2 text-dark" htmlFor="description">
                  Name
                </label>
                <input
                  type="text"
                  name="description"
                  className="form-control"
                  id="description"
                  aria-describedby="description"
                  placeholder="Give it a descriptive name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="mb-2 text-dark">
                  What should I do when I encounter a violation?
                </label>
                {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="actionType"
                    id="kick"
                    value="kick"
                    defaultChecked={integration.meta.action_type === "kick"}
                  />
                  <label className="form-check-label" htmlFor="kick">
                    Kick member
                  </label>
                </div> */}
                {/* <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="actionType"
                    id="ban"
                    value="ban"
                    defaultChecked={integration.meta.action_type === "ban"}
                  />
                  <label className="form-check-label" htmlFor="ban">
                    Ban member
                  </label>
                </div> */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="actionType"
                    id="warn"
                    value="warn"
                    checked={actionType === "warn"}
                    onChange={(e) => e.target.checked && setActionType("warn")}
                  />
                  <label className="form-check-label" htmlFor="warn">
                    Warn member
                  </label>
                </div>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="actionType"
                    id="delete"
                    value="delete"
                    checked={actionType === "delete"}
                    onChange={(e) =>
                      e.target.checked && setActionType("delete")
                    }
                  />
                  <label className="form-check-label" htmlFor="delete">
                    Warn member and delete message
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="mb-2 text-dark" htmlFor="warningText">
                  Warning message
                </label>
                <textarea
                  type="text"
                  name="warningText"
                  className="form-control"
                  id="warningText"
                  aria-describedby="warningText"
                  placeholder="Warning text to send"
                  value={warningText}
                  onChange={(e) => setWarningText(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label className="mb-8 text-dark" htmlFor="warningText">
                  How aggressive should I be in content safety?
                </label>
                <div className="w-75 m-auto">
                  <Slider
                    defaultValue={integration.meta.behavior || 0.1}
                    min={0.1}
                    max={0.9}
                    marks={SCALE}
                    value={behavior}
                    onChange={(value) => setBehavior(value)}
                  />
                </div>
              </div>
            </form>
          </div>
          <div className="modal-footer mt-6">
            <button
              type="button"
              className="btn btn-xs rounded-5"
              data-dismiss="modal"
              id="close_modal_btn"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary btn-xs rounded-5 px-6"
              disabled={saving}
              onClick={() =>
                formRef.current.dispatchEvent(
                  new Event("submit", { cancelable: true, bubbles: true })
                )
              }
            >
              Save changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Integrate;
